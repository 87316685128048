li.weather-warning {
  display: flex;
  align-items: stretch;
  border-radius: 6px;
  box-sizing: border-box;
  width: var(--popup-width);
}
li.weather-warning:not(:last-child) {
  margin-bottom: 12px;
}
li.weather-warning .icon {
  width: 100px;
  min-height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -1px 0 -1px -1px;
  flex: 0 0 auto;
  border-radius: 6px;
}
li.weather-warning.seriousness-r {
  border: 1px solid #fbddd4;
  background: linear-gradient(90deg, transparent, #FFF6F5) right top / 278px 100% no-repeat;
}
li.weather-warning.seriousness-r .icon {
  border: 2px solid #ffcece;
}
li.weather-warning.seriousness-o {
  border: 1px solid #fde2cb;
  background: linear-gradient(90deg, transparent, #fff4eb) right top / 278px 100% no-repeat;
}
li.weather-warning.seriousness-o .icon {
  border: 2px solid #ffbba7;
}
li.weather-warning.seriousness-y {
  border: 1px solid #ede0c8;
  background: linear-gradient(90deg, transparent, #FFF7ED) right top / 278px 100% no-repeat;
}
li.weather-warning.seriousness-y .icon {
  border: 2px solid #ffce85;
}
li.weather-warning .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 23px;
  padding: 10px 0;
}
li.weather-warning .name-date {
  margin-bottom: 6px;
  font-size: 0;
}
li.weather-warning .name-date > span:not(:last-child) {
  margin-right: 8px;
}